<template>
  <v-row justify="center">
    <v-dialog :value="true" max-width="600" @input="$emit('closeDialog')">
      <v-card class="pa-8">
        <!-- init step -->
        <v-row class="justify-end">
          <v-btn icon color="primary" @click="$emit('closeDialog')">
            <v-icon v-text="'close'" />
          </v-btn>
        </v-row>
        <v-row>
          <v-col cols="12" class="py-1 px-3">
            <h2 class="d-block mb-4" v-text="$t('project.competition.bans.applyBan')" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <div class="d-flex align-center">
              <v-icon color="primary" v-text="'person_outline'" />
              <span class="ml-3 subtitle-2 font-weight-regular">{{ `${event.player.firstName} ${event.player.lastName}` }}</span>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <v-icon color="primary" v-text="'calendar_today'" /> <span class="ml-3 subtitle-2 font-weight-regular">{{ date }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="d-flex align-center">
              <v-icon color="primary" v-text="'sports_basketball'" /><span class="ml-3 subtitle-2 font-weight-regular">{{ event.match.name }}</span>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <p v-text="$t('project.competition.bans.applyBanDisclaimer')" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model.number="form.banMatchCount" type="number" outlined dense min="1" max="50" :label="$t('project.competition.bans.banMatchCount')"
              :error-messages="getFieldErrors('banMatchCount')" @keypress="validateKeyNumeric($event)" @blur="$v.form.banMatchCount.$touch()"
            />
          </v-col>
        </v-row>
        <v-card-actions class="pa-0">
          <v-row>
            <v-col cols="12" class="py-1 px-3 d-sm-flex justify-sm-center">
              <v-btn color="primary" class="w-100 w-sm-auto" @click="applyBan" v-text="$t('project.competition.bans.applyBan')" />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { required, numeric, maxValue } from 'vuelidate/lib/validators'
import { DateTime } from 'luxon'
import formUtils from '@/utils/mixins/formUtils'

export default {
  name: 'ApplyBanDialog',
  mixins: [formUtils],
  props: {
    event: { type: Object, default: null },
    organizationId: { type: String, default: null },
    project: { type: Object, default: null },
  },
  data: () => ({ form: { banMatchCount: null } }),
  computed: {
    date: ({ event, project }) => DateTime.fromSeconds(event.date.seconds, { zone: project.timezone }).toLocaleString(),
  },
  validations: { form: { banMatchCount: { required, numeric, maxValue: maxValue(50) } } },
  methods: {
    async applyBan() {
      if (!this.isFormValid()) return
      const playerBanMatchCount = ((this.event.player?.ban?.matchCount ?? 0) - this.event.banMatchCount) + this.form.banMatchCount
      const { organizationId, event: { id, cohortId, matchId, playerId } } = this
      await this.runAsync(() => {
        this.$store.dispatch(
          'competition/updatePlayerBanMatchCount',
          { organizationId, projectId: this.project.id, cohortId, id: playerId, data: { 'ban.matchCount': playerBanMatchCount } },
        )
        this.$store.dispatch(
          'competition/updateEventBanMatchCount',
          { organizationId, projectId: this.project.id, cohortId, matchId, id, data: { banMatchCount: this.form.banMatchCount } },
        )
      })
      this.$emit('closeDialog')
    },
  },
}
</script>
